import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageItem from './ImageItem';

class Layout extends React.Component {
  render() {
    return (
      <Container fluid className="mt-5" style={{ width: '80%' }}>
        
        <Row className="justify-content-md-center">
          <Col className="mb-5" xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
            <ImageItem orientation={'portrait'} image={0} />
          </Col>
          <Col className="mb-5" xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
            <ImageItem orientation={'portrait'} image={1} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={0} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={1} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5" xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
            <ImageItem orientation={'portrait'} image={2} />
          </Col>
          <Col className="mb-5" xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
            <ImageItem orientation={'portrait'} image={3} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={2} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={3} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={4} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5" xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
            <ImageItem orientation={'portrait'} image={4} />
          </Col>
          <Col className="mb-5" xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
            <ImageItem orientation={'portrait'} image={5} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={5} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5" xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
            <ImageItem orientation={'portrait'} image={6} />
          </Col>
          <Col className="mb-5" xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
            <ImageItem orientation={'portrait'} image={7} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={6} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5" xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
            <ImageItem orientation={'portrait'} image={11} />
          </Col>
          <Col className="mb-5" xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
            <ImageItem orientation={'portrait'} image={13} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={7} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5" xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
            <ImageItem orientation={'portrait'} image={8} />
          </Col>
          <Col className="mb-5" xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
            <ImageItem orientation={'portrait'} image={9} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={8} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={9} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={10} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={11} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={12} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={13} />
          </Col>
        </Row>
        
        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={14} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={15} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={16} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={17} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={18} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={19} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={23} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={24} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={25} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={26} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={20} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={21} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5">
            <ImageItem orientation={'landscape'} image={22} />
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="mb-5" md={{ span: 5 }}>
            <ImageItem orientation={'portrait'} image={12} />
          </Col>
        </Row>

      </Container>
    );
  }
}

export default Layout;
import React from 'react';
import { Image } from 'react-bootstrap';

const landImages = [
    require('./assets/q8_la_1.JPG'),
    require('./assets/q8_la_2.JPG'),
    require('./assets/r8_la_7.JPG'),
    require('./assets/r8_la_6.JPG'),
    require('./assets/r8_la_10.JPG'),
    require('./assets/viper_la_1.JPG'),
    require('./assets/supra_la_1.JPG'),
    require('./assets/gt2_la_1.JPG'),
    require('./assets/q8_la_6.JPG'),
    require('./assets/q8_la_5.JPG'),
    require('./assets/f430_la_1.JPG'),
    require('./assets/q8_la_3.JPG'),
    require('./assets/q8_la_4.JPG'),
    require('./assets/rs4_la_1.JPG'),
    require('./assets/ttrs_la_1.JPG'),
    require('./assets/r8_la_1.JPG'),
    require('./assets/r8_la_2.JPG'),
    require('./assets/r8_la_3.JPG'),
    require('./assets/r8_la_4.JPG'),
    require('./assets/r8_la_5.JPG'),
    require('./assets/r8_la_8.JPG'),
    require('./assets/r8_la_9.JPG'),
    require('./assets/r8_la_11.JPG'),
    require('./assets/m3_la_1.JPG'),
    require('./assets/m3_la_2.JPG'),
    require('./assets/m3_la_3.JPG'),
    require('./assets/m3_la_4.JPG')
];

const portImages = [
    require('./assets/q8_p_1.JPG'),
    require('./assets/q8_p_2.JPG'),
    require('./assets/r8_p_2.JPG'),
    require('./assets/r8_p_1.JPG'),
    require('./assets/viper_p_4.JPG'),
    require('./assets/viper_p_2.JPG'),
    require('./assets/viper_p_3.JPG'),
    require('./assets/viper_p_1.JPG'),
    require('./assets/gt2_p_1.JPG'),
    require('./assets/q8_p_3.JPG'),
    require('./assets/crosstrek_p_1.JPG'),
    require('./assets/m3_p_1.JPG'),
    require('./assets/m3_p_3.JPG'),
    require('./assets/m3_p_2.JPG')
];

class ImageItem extends React.Component {
    render () {
        var image = this.props.orientation === 'portrait' ? portImages[this.props.image] : landImages[this.props.image];

        return (
            <Image src={image} width='100%' height='90%' />
        );
    }
}

export default ImageItem;
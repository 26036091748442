import React from 'react';
import Layout from './Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderPage from './HeaderPage';
import FooterPage from './FooterPage';

function App() {
  document.body.style = 'background-color: black;';

  return (
    <div className="App">
      <HeaderPage />
      <Layout />
      <FooterPage />
    </div>
  );
}

export default App;

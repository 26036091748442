import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const HeaderPage = () => {
  return (
    <Navbar bg="dark" variant="dark">
        <Navbar.Brand>Sam Ruhe Photography</Navbar.Brand>
        <Nav className="ml-auto">
            <Nav.Link target="_blank" href="https://www.instagram.com/sam.ruhe/">
                Instagram
            </Nav.Link>
        </Nav>
    </Navbar>
  );
}

export default HeaderPage;

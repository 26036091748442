import React from 'react';
import { Navbar } from 'react-bootstrap';

const FooterPage = () => {
  return (
    <Navbar sticky="bottom" bg="dark" variant="dark">
        <Navbar.Text style={{ fontSize: 'small' }}>
            All images property of Sam Ruhe. May not be reused for any purpose without the written consent of Sam Ruhe.
        </Navbar.Text>
    </Navbar>
  );
}

export default FooterPage;